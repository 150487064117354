.footer {
    .map-section {
        position: relative;

        .google-map {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
        }

        .details-box {
            width: 100%;
            background-color: rgba($color: #323232, $alpha: 0.9);
            padding: 25px 20px;
            z-index: 100;
    
            h5 {
                color: #FFF;
                margin-bottom: 0px;
                font-weight: 700;
                margin-bottom: 15px;
            }
    
            .working-days {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 5px;
                margin-bottom: 15px;
                border-bottom: 1px solid rgba($color: #FFFFFF, $alpha: 0.3);
    
                .day {
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    color: #FFF;
                }
    
                .time {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 0px;
                    color: #FFF;
                    font-weight: 400;
                }
            }
    
            .contact-details-heading {
                margin-top: 30px;
                margin-bottom: 0px;
            }
    
            .cta {
                margin-top: 10px;
    
                a {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    color: #FFF;
                    text-decoration: none;
                    font-size: 15px;
    
                    img {
                        height: 16px;
                        width: auto;
                        margin-right: 10px;
                    }
                }
            }

            .social-media-icons {
                display: flex;
                align-items: center;
                margin-top: 10px;

                a {
                    display: block;
                    padding-right: 15px;
                }
            }
        }
    }

    .copyright-section {
        background-color: #2F71B3;
        padding: 10px 0px;

        h5 {
            color: #FFF;
            margin-bottom: 0px;
            font-size: 15px;
            font-weight: 500;
        }

        .page-links {
            display: flex;
            align-items: center;

            .link-item {
                color: #FFF;
                text-decoration: none;
                font-size: 15px;
                font-weight: 500;
                padding-right: 25px;
            }
        }
    }
}