.services {
    .page-banner {
        padding: 100px 0px;
        background-image: url('../../../assets/page-banner.png');
        background-size: cover;
        text-align: center;

        h1 {
            color: #FFF;
            margin-bottom: 0px;
            font-weight: 800;
            font-size: 50px;
        }

        h5 {
            color: #FFF;
            font-weight: 500;
            font-style: italic;
            margin-top: 20px;
        }

        .appointment-btn {
            margin-top: 20px;
            background-color: #2F71B3;
            border-color: #2F71B3;
            font-size: 15px;
            font-weight: 600;
            padding: 10px 20px;
        }
    }

    .services-list {
        padding: 50px 0px;

        .services-box-card {
            border: 1px solid #E5E5E5;
            margin-bottom: 20px;

            .services-box-cardbody {
                img {
                    width: 50px;
                    height: auto;
                }
            
                h5 {
                    font-size: 17px;
                    font-weight: 600;
                    margin-bottom: 0px;
                }
            
                p {
                    margin-top: 10px;
                    margin-bottom: 0px;
                    font-size: 14px;
                    font-weight: 500;
                }
            
                .navigation-link {
                    display: block;
                    font-weight: 600;
                    text-decoration: none;
                    color: #00AE86;
                    margin-top: 5px;
                }
            }
        }

        .services-box-card:hover {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
    }
}