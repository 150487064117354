.service-detail {
    .banner-text {
        background-image: url('../../../assets/page-banner.png');
        background-size: cover;
        padding: 50px 0px;

        h5 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 5px;
            color: rgba($color: #FFF, $alpha: 0.7);
        }

        h1 {
            font-weight: 700;
            margin-bottom: 0px;
            color: #FFF;
        }

        p {
            font-size: 16px;
            margin-top: 20px;
            margin-bottom: 0px;
            line-height: 28px;
            color: #FFF;
        }

        .consultation-btn {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 600;
            background-color: #2F71B3;
            border: 2px solid #2F71B3;
            padding: 10px 20px;
            text-transform: uppercase;
        }

        .consultation-btn:hover {
            background-color: transparent;
            color: #FFF;
        }

        .service-banner {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .quote-box {
        padding: 50px 0px;

        .quote {
            position: relative;
            background-color: #F4F7F9;
            border-radius: 5px;
            padding: 35px 40px;

            h5 {
                color: #323232;
                font-size: 18px;
                line-height: 28px;
                font-weight: 600;
                margin-bottom: 0px;
            }

            .left-quote {
                position: absolute;
                top: 15px;
                left: 15px;

                img {
                    height: 60px;
                    width: 60px;
                }
            }

            .right-quote {
                position: absolute;
                bottom: 15px;
                right: 15px;

                img {
                    height: 60px;
                    width: 60px;
                }
            }
        }
    }

    .section-heading {
        background-color: #2F71B3;
        padding: 20px 0px;

        h2 {
            font-size: 30px;
            font-weight: 800;
            color: #FFF;
            margin-bottom: 0px;
        }
    }

    .description {
        padding: 50px 0px;

        p {
            color: #555555;
            font-size: 16px;
            font-weight: 500;
            line-height: 26px;
            margin-bottom: 20px;
        }

        h5 {
            font-size: 25px;
            font-weight: 600;
            margin-bottom: 20px;
            color: #323232;
            margin-top: 25px;
        }

        .video-box {
            position: relative;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }

            .overlay {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: rgba($color: #323232, $alpha: 0.5);
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                .play-btn {
                    background-color: #FFF;
                    border-color: #FFF;
                    border-radius: 50%;
                    padding: 17px 20px;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                    svg {
                        margin-top: -5px;
                    }
                }
            }
        }
    }

    .speciality {
        padding-bottom: 50px;

        .box {
            background-color: #F4F7F9;
            border-radius: 10px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-top-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            .box-content {
                padding: 0px 50px;

                h5 {
                    font-size: 18px;
                    font-weight: 700;
                    color: #2F71B3;
                }

                .short-heading {
                    margin-top: 20px;
                    font-weight: 600;
                    color: #2F71B3;
                }

                h2 {
                    font-weight: 800;
                    color: #323232;
                }

                p {
                    font-size: 16px;
                    color: #323232;
                    font-weight: 500;
                    margin-bottom: 0px;
                    margin-top: 20px;
                }
            }
        }
    }

    .calendar {
        margin-top: -80px;

        .calendly-inline-widget {
            height: 700px !important;
        }
    }
}

/* NEW SERVICES PAGE */
.new-services-page {
    .banner-text {
        background-image: url('../../../assets/page-banner.png');
        background-size: cover;
        padding: 50px 0px;

        h5 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 5px;
            color: rgba($color: #FFF, $alpha: 0.7);
        }

        h1 {
            font-weight: 700;
            margin-bottom: 0px;
            color: #FFF;
        }

        p {
            font-size: 16px;
            margin-top: 20px;
            margin-bottom: 0px;
            line-height: 28px;
            color: #FFF;
        }

        .consultation-btn {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 600;
            background-color: #2F71B3;
            border: 2px solid #2F71B3;
            padding: 10px 20px;
            text-transform: uppercase;
        }

        .consultation-btn:hover {
            background-color: transparent;
            color: #FFF;
        }

        .service-banner {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .banner-text.ivf-banner {
        background-image: url('../../../assets/ivf-banner.webp');
    }

    .paths-to-conception {
        padding: 60px 0px;

        h2 {
            font-size: 40px;
            margin-bottom: 0px;
            font-weight: 700;
            color: #000;
            text-align: center;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            margin-top: 10px;
            margin-bottom: 0px;
            text-align: center;
            line-height: 28px;
        }

        .paths-box {
            background-color: rgba($color: #2F71B3, $alpha: 0.1);
            padding: 35px;
            border-radius: 10px;

            .vertical-line {
                width: 2px;
                height: 150px;
                background-color: #2F71B3;
                border-radius: 5px;
                margin-left: auto;
                margin-right: auto;
            }

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 10px;
            }

            img.small-height {
                height: 320px;
                object-fit: cover;
            }

            h3 {
                font-size: 30px;
                font-weight: 700;
            }

            p {
                font-size: 15.5px;
                text-align: left;
                margin-top: 15px;
            }
        }
    }

    .ivf-with-vivek-kakkad {
        background-color: rgba($color: #2F71B3, $alpha: 0.7);
        padding: 50px;
        border-radius: 20px;
        margin: 0px 50px;

        .btn-align-last {
            display: flex;
            justify-content: flex-end;

            .book-appointment {
                margin-top: 20px;
                font-size: 14px;
                font-weight: 600;
                background-color: #FFF;
                border: 2px solid #FFF;
                padding: 15px 25px;
                text-transform: uppercase;
                color: #000;
            }
        
            .book-appointment:hover {
                background-color: transparent;
                color: #FFF;
            }
        }

        h2 {
            font-size: 40px;
            color: #FFF;
            font-weight: 700;
            text-align: center;
        }

        p {
            font-size: 16px;
            color: #FFF;
            line-height: 28px;
            font-weight: 500;
        }

        .treatment-card {
            border: 0px solid transparent;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

            .treatment-cardbody {
                .step-circle {
                    height: 55px;
                    width: 55px;
                    border-radius: 50%;
                    background-color: #00AE86;
                    font-size: 18px;
                    color: #FFF;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                h5 {
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    margin-top: 15px;
                }

                p {
                    font-size: 15px;
                    font-weight: 400;
                    color: #323232;
                    margin-bottom: 0px;
                    line-height: 24px;
                    margin-top: 10px;
                }

                .second-text {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .schedule-consultation {
        padding: 60px 0px;

        h2 {
            font-size: 35px;
            font-weight: 700;
        }

        h5 {
            font-size: 18px;
            line-height: 28px;
            font-weight: 400;
        }

        .appointment-card {
            border: 2px solid #E5E5E5;

            .appointment-cardbody {
                padding: 20px;

                h4 {
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 15px;
                }

                p {
                    font-size: 15px;
                    font-weight: 400;
                    margin-bottom: 0px;
                    line-height: 25px;
                }
            }

            .appointment-cardfooter {
                background-color: #FFF;
                border-top: 0px solid #FFF;
                margin-bottom: 10px;

                .appointment-btn {
                    font-size: 14px;
                    font-weight: 600;
                    background-color: #2F71B3;
                    border: 2px solid #2F71B3;
                    padding: 8px 15px;
                    text-transform: uppercase;
                }
            
                .appointment-btn:hover {
                    background-color: transparent;
                    color: #2F71B3;
                }
            }
        }
    }
    
    .holistic-care {
        padding-bottom: 50px;
        padding-top: 40px;

        h2 {
            font-size: 30px;
            margin-bottom: 10px;
            font-weight: 700;
            color: #000;
            text-align: center;
        }

        .tips-box {
            text-align: center;

            .steps-icon-box {
                height: 100px;
                width: 100px;
                border-radius: 50%;
                background-color: #00AE86;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
        
                img {
                    width: 40px;
                    height: auto;
                }
            }

            h5 {
                font-size: 16px;
                margin-bottom: 0px;
                margin-top: 10px;
                font-weight: 600;
            }
        }
    }
}

/* LAPAROSCOPY AND HYSTEROSCOPY */
.laparoscopy {
    .banner-text {
        background-image: url('../../../assets/page-banner.png');
        background-size: cover;
        padding: 50px 0px;

        h5 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 5px;
            color: rgba($color: #FFF, $alpha: 0.7);
        }

        h1 {
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 0px;
            color: #FFF;
        }

        p {
            font-size: 16px;
            margin-top: 20px;
            margin-bottom: 0px;
            line-height: 28px;
            color: #FFF;
        }

        .consultation-btn {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 600;
            background-color: #2F71B3;
            border: 2px solid #2F71B3;
            padding: 10px 20px;
            text-transform: uppercase;
        }

        .consultation-btn:hover {
            background-color: transparent;
            color: #FFF;
        }

        .service-banner {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .what-is-laparoscopy {
        padding: 60px 0px;

        h2 {
            font-size: 40px;
            margin-bottom: 0px;
            font-weight: 700;
            color: #000;
        }

        p {
            font-size: 16px;
            margin-top: 15px;
            margin-bottom: 0px;
            line-height: 26px;
        }

        h3 {
            font-size: 25px;
            font-weight: 600;
            margin-top: 20px;
            color: #000;
        }

        .reason-box {
            width: 100%;
            padding: 15px 20px;
            border-radius: 5px;
            background-color: #FFF;
            box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
            margin-bottom: 10px;
            margin-top: 10px;

            p {
                font-size: 15px;
                font-weight: 500;
                margin-top: 0px;
                text-align: center;
            }
        }

        ol {
            margin-top: 15px;

            li {
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 15px;
            }
        }
    }

    .what-is-hysteroscopy {
        padding: 60px 0px;
        background-color: rgba($color: #2F71B3, $alpha: 0.1);

        h2 {
            font-size: 40px;
            margin-bottom: 0px;
            font-weight: 700;
            color: #000;
        }

        p {
            font-size: 16px;
            margin-top: 15px;
            margin-bottom: 0px;
            line-height: 26px;
        }

        h3 {
            font-size: 25px;
            font-weight: 600;
            margin-top: 20px;
            color: #000;
        }

        .reason-box {
            width: 100%;
            padding: 15px 20px;
            border-radius: 5px;
            background-color: #FFF;
            box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
            margin-bottom: 10px;
            margin-top: 10px;

            p {
                font-size: 15px;
                font-weight: 500;
                margin-top: 0px;
                text-align: center;
            }
        }

        ol {
            margin-top: 15px;

            li {
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 15px;
            }

            li:last-child {
                margin-bottom: 0px;
            }
        }
    }

    .holistic-care {
        padding: 60px 0px;

        h2 {
            font-size: 30px;
            margin-bottom: 10px;
            font-weight: 700;
            color: #000;
            text-align: center;
        }

        .tips-box {
            text-align: center;

            .steps-icon-box {
                height: 100px;
                width: 100px;
                border-radius: 50%;
                background-color: #00AE86;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;

                img {
                    width: 40px;
                    height: auto;
                }
            }

            h5 {
                font-size: 16px;
                margin-bottom: 0px;
                margin-top: 10px;
                font-weight: 600;
            }
        }
    }
}

/* FERTILITY PRESERVATION */
.fertility-preservation {
    .banner-text {
        background-image: url('../../../assets/page-banner.png');
        background-size: cover;
        padding: 50px 0px;

        h5 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 5px;
            color: rgba($color: #FFF, $alpha: 0.7);
        }

        h1 {
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 0px;
            color: #FFF;
        }

        p {
            font-size: 16px;
            margin-top: 20px;
            margin-bottom: 0px;
            line-height: 28px;
            color: #FFF;
        }

        .consultation-btn {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 600;
            background-color: #2F71B3;
            border: 2px solid #2F71B3;
            padding: 10px 20px;
            text-transform: uppercase;
        }

        .consultation-btn:hover {
            background-color: transparent;
            color: #FFF;
        }

        .service-banner {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .when-why-fertility-preservation {
        padding: 60px 0px;

        .when-box {
            background-color: #F5F5F5;
            padding: 30px;
            border-radius: 5px;

            h2 {
                font-size: 25px;
                margin-bottom: 0px;
                font-weight: 700;
                color: #000;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                margin-top: 10px;
                line-height: 26px;
            }
        
            ol, ul {
                margin-top: 15px;
        
                li {
                    font-size: 16px;
                    line-height: 26px;
                    margin-bottom: 15px;
                }
            }
        }

        .why-box {
            background-color: rgba($color: #2F71B3, $alpha: 0.1);
            border-radius: 5px;
            padding: 30px;
            height: 100%;

            h2 {
                font-size: 25px;
                margin-bottom: 0px;
                font-weight: 700;
                color: #000;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                margin-top: 10px;
                line-height: 26px;
            }

            ol,
            ul {
                margin-top: 15px;

                li {
                    font-size: 16px;
                    line-height: 26px;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .common-fertility-preservation {
        h2 {
            font-size: 30px;
            margin-bottom: 10px;
            font-weight: 700;
            color: #000;
        }

        .preservation-box {
            width: 100%;
            padding: 15px 20px;
            border-radius: 5px;
            background-color: #FFF;
            box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
            margin-bottom: 10px;
            margin-top: 10px;

            h5 {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 10px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 0px;
                color: #000;
            }
        }
    }

    .holistic-care {
        padding: 60px 0px;

        h2 {
            font-size: 30px;
            margin-bottom: 10px;
            font-weight: 700;
            color: #000;
            text-align: center;
        }

        .tips-box {
            text-align: center;

            .steps-icon-box {
                height: 100px;
                width: 100px;
                border-radius: 50%;
                background-color: #00AE86;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;

                img {
                    width: 40px;
                    height: auto;
                }
            }

            h5 {
                font-size: 16px;
                margin-bottom: 0px;
                margin-top: 10px;
                font-weight: 600;
            }
        }
    }
}