@media (max-width: 575px) {
    .header .top-header .top-header-links {
        display: block !important;
    }

    .header .top-header .top-header-links .left-section {
        justify-content: center;
        margin-bottom: 10px;
    }

    .header .top-header .top-header-links .right-section {
        justify-content: center;
    }

    .homepage .banner {
        padding: 50px 0px !important;
    }

    .homepage .banner h1 {
        font-size: 30px !important;
    }

    .homepage .banner h4 {
        font-size: 18px !important;
    }

    .homepage .banner .appointment-btn {
        margin-bottom: 15px !important;
    }

    .homepage .speciality .box .about-details .cta-btns .cta {
        padding: 0px 20px 0px 0px !important;
    }

    .homepage .speciality .box .about-details .cta-btns .cta:last-child {
        padding-left: 20px !important;
    }

    .homepage .speciality .box .about-details .expertise-box {
        margin-bottom: 10px !important;
        height: auto !important;
    }

    .homepage .why-questions .question-box {
        margin-bottom: 15px !important;
    }

    .homepage .why-questions .question-box .overlay .green-box {
        padding: 10px 20px !important;
    }

    .homepage .why-questions .question-box .overlay .green-box p {
        font-size: 15px !important;
    }

    .homepage .why-questions .questions-section {
        padding: 0px !important;
    }

    .homepage .why-questions .questions-section h2 {
        font-size: 25px !important;
    }

    .homepage .about-me h2 {
        font-size: 25px !important;
    }

    .homepage .about-me img {
        margin-bottom: 15px !important;
    }

    .homepage .testimonials .testimonial-box {
        margin-bottom: 40px !important;
    }

    .homepage .testimonials .testimonial-box:last-child {
        margin-bottom: 0px;
    }

    .homepage .about-video {
        padding: 0px 0px 50px 0px !important;
    }

    .homepage .about-video .about-details {
        padding: 20px !important;
    }

    .homepage .about-video .about-details h2 {
        font-size: 25px !important;
    }

    .homepage .about-video .video-box {
        margin-bottom: 15px !important;
    }

    .footer .map-section .google-map {
        position: relative !important;
    }

    .copy-links {
        display: block !important;
    }

    .footer .copyright-section .page-links {
        margin-top: 10px !important;
        justify-content: center;
    }

    .footer .copyright-section h5 {
        text-align: center;
    }

    .service-detail .page-banner h1 {
        font-size: 30px !important;
    }

    .service-detail .quote-box .quote h5 {
        font-size: 15px !important;
        line-height: 22px !important;
    }

    .service-detail .quote-box {
        padding: 30px 0px !important;
    }

    .service-detail .section-heading h2 {
        font-size: 25px !important;
    }

    .service-detail .description p {
        font-size: 15px !important;
        line-height: 24px !important;
    }

    .service-detail .speciality .box .box-content {
        padding: 15px !important;
    }

    .service-detail .speciality .box .box-content p {
        font-size: 15px !important;
    }

    .about-us .page-banner h1 {
        font-size: 40px !important;
    }

    .about-us .services .grey-box {
        padding: 20px !important;
    }

    .about-us .about-details h2 {
        font-size: 30px !important;
    }

    .about-us .about-details h5 {
        font-size: 20px !important;
    }

    .about-us .vision-values h5 {
        margin-top: 15px !important;
    }

    .about-us .quote {
        margin: 0px 10px !important;
        padding: 50px 0px !important;
    }

    .about-us .quote h2 {
        font-size: 25px !important;
        line-height: 35px !important;
    }

    .about-us .testimonials .testimonial-box {
        margin-top: 40px !important;
    }

    .contact-us .page-banner h1 {
        font-size: 40px !important;
    }

    .contact-us .message-form .sidebar {
        margin-top: 20px;
    }

    .gallery .gallery-photos .blogs-list {
        margin-top: 15px !important;
    }

    .our-blogs .page-banner h1 {
        font-size: 40px !important;
    }

    .our-blogs .blogs-list .blog-latest-box img {
        margin-bottom: 10px !important;
    }

    .blog-detail .page-banner h1 {
        font-size: 40px !important;
    }

    .blog-detail .blog-content .blog-detail-sidebar {
        margin-top: 20px;
    }

    .new-services-page .banner-text p {
        font-size: 14px !important;
        line-height: 24px !important;
    }

    .new-services-page .banner-text .service-banner {
        margin-top: 20px;
    }

    .new-services-page .paths-to-conception h2 {
        font-size: 30px !important;
    }

    .new-services-page .paths-to-conception p {
        font-size: 14px !important;
        line-height: 24px !important;
    }

    .new-services-page .paths-to-conception .paths-box {
        padding: 20px !important;
    }

    .new-services-page .paths-to-conception .paths-box h3 {
        font-size: 25px !important;
    }

    .new-services-page .paths-to-conception .paths-box p {
        margin-top: 10px !important;
    }

    .new-services-page .paths-to-conception .paths-box img {
        margin-bottom: 15px !important;
    }

    .new-services-page .paths-to-conception .paths-box img {
        margin-top: 15px !important;
    }

    .new-services-page .ivf-with-vivek-kakkad {
        padding: 20px !important;
        margin: 0px 10px !important;
    }

    .new-services-page .ivf-with-vivek-kakkad h2 {
        font-size: 30px !important;
    }

    .new-services-page .schedule-consultation h2 {
        font-size: 30px !important;
    }

    .new-services-page .schedule-consultation h5 {
        font-size: 16px !important;
    }

    .new-services-page .schedule-consultation .appointment-card {
        margin-bottom: 20px;
    }

    .new-services-page .holistic-care .tips-box {
        margin-bottom: 15px;
    }

    .new-services-page .holistic-care {
        padding-top: 0px !important;
    }

    .fertility-preservation .banner-text .service-banner {
        margin-top: 20px;
    }

    .fertility-preservation .when-why-fertility-preservation .when-box {
        margin-bottom: 20px;
    }

    .fertility-preservation .common-fertility-preservation h2 {
        font-size: 25px !important;
    }

    .fertility-preservation .holistic-care .tips-box {
        margin-bottom: 20px;
    }

    .laparoscopy .banner-text .service-banner {
        margin-top: 20px;
    }

    .laparoscopy .what-is-laparoscopy h2 {
        font-size: 25px !important;
    }

    .laparoscopy .what-is-hysteroscopy h2 {
        font-size: 25px !important;
    }

    .laparoscopy .holistic-care h2 {
        font-size: 25px !important;
    }

    .laparoscopy .holistic-care .tips-box {
        margin-bottom: 20px;
    }

    .blog-detail .blog-content img {
        height: 300px !important;
    }

    .press-media .about-video .video-box video {
        width: 100% !important;
        height: auto !important;
    }

    .press-media .about-video .about-details {
        padding: 20px !important;
    }

    .press-media .img-slider h2 {
        font-size: 25px !important;
    }

    .press-media .page-banner h1  {
        font-size: 30px !important;
    }
}