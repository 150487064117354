.about-us {
    .page-banner {
        padding: 80px 0px;
        background-image: url('../../../assets/page-banner.png');
        background-size: cover;
        text-align: center;

        h1 {
            color: #FFF;
            margin-bottom: 0px;
            font-weight: 800;
            font-size: 50px;
        }

        h5 {
            color: #FFF;
            font-weight: 500;
            font-style: italic;
            margin-top: 20px;
        }

        .appointment-btn {
            margin-top: 20px;
            background-color: #2F71B3;
            border-color: #2F71B3;
            font-size: 15px;
            font-weight: 600;
            padding: 10px 20px;
        }
    }

    .services {
        padding: 50px 0px;

        .grey-box {
            background-color: #F3F3F3;
            border-radius: 5px;
            padding: 30px;

            .services-box {
                background-color: #FFF;
                border: 1px solid #EBEBEB;
                padding: 20px;

                img {
                    width: 70px;
                    height: auto;
                }

                h5 {
                    font-size: 16px;
                    font-weight: 700;
                    margin-top: 15px;
                    margin-bottom: 0px;
                }
            }

            .services-box:hover {
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }
        }
    }

    .about-details {
        padding-bottom: 50px;

        h2 {
            font-weight: 800;
            color: #2F71B3;
            font-size: 35px;
        }

        h5 {
            font-size: 25px;
            font-weight: 600;
            color: #323232;
            font-style: italic;
        }

        .imp-text {
            font-weight: 700;
            font-size: 16px;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        p {
            font-weight: 500;
            margin-top: 15px;
            line-height: 26px;

            b {
                color: #2F71B3;
            }
        }

        .signature {
            margin-top: 30px;

            .doctor-name {
                font-size: 16px;
                margin-bottom: 0px;
                font-weight: 700;
                margin-top: 10px;
            }
        }

        .banner-about {
            width: 100%;
            height: auto;
            border-radius: 10px;
            object-fit: cover;
        }
    }

    .vision-values {
        padding: 50px 0px;

        h5 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        h2 {
            font-weight: 800;
            color: #2F71B3;
            font-size: 35px;
        }

        .details {
            margin-top: 10px;

            .box {
                margin-top: 20px;

                h5 {
                    margin-top: 10px;
                    font-weight: 800;
                    font-size: 18px;
                    margin-bottom: 5px;
                }
            }
        }

        .banner-img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .quote {
        background-image: url('../../../assets/quote-banner.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        padding: 100px 0px;
        margin: 0px 50px;
        margin-bottom: 50px;
        border-radius: 10px;

        img {
            height: 40px;
            width: 40px;
        }

        h2 {
            font-style: italic;
            font-weight: 700;
            line-height: 45px;
            color: #FFF;
            margin-top: 15px;
        }

        .testimonial-name {
            margin-top: 30px;

            h5 {
                font-size: 18px;
                color: #FFF;
                margin-bottom: 5px;
                font-weight: 600;
            }

            p {
                font-size: 16px;
                color: #FFF;
                font-weight: 500;
            }
        }
    }

    .testimonials {
        padding: 50px 0px;

        h2 {
            font-size: 30px;
            font-weight: 800;
            margin-bottom: 15px;
            color: #2F71B3;
        }

        .testimonial-box {
            position: relative;
            border-radius: 5px;
            padding: 50px 20px 20px 20px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            p {
                font-size: 15px;
                color: #727070;
                margin-bottom: 0px;
                font-weight: 400;
            }

            h5 {
                font-size: 15px;
                color: #000;
                margin-bottom: 0px;
                font-weight: 600;
                margin-top: 10px;
            }

            .user-profile {
                position: absolute;
                top: -30px;
                height: 60px;
                width: 60px;
                border-radius: 50%;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }
    }
}

