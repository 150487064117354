.gallery {
    .page-banner {
        padding: 80px 0px;
        background-image: url('../../../assets/page-banner.png');
        background-size: cover;
        text-align: center;

        h1 {
            color: #FFF;
            margin-bottom: 0px;
            font-weight: 800;
            font-size: 50px;
        }

        h5 {
            color: #FFF;
            font-weight: 500;
            font-style: italic;
            margin-top: 20px;
        }

        .appointment-btn {
            margin-top: 20px;
            background-color: #2F71B3;
            border-color: #2F71B3;
            font-size: 15px;
            font-weight: 600;
            padding: 10px 20px;
        }
    }

    .gallery-photos {
        padding: 50px 0px;

        .blogs-list {
            background-color: rgba($color: #2F71B3, $alpha: 0.1);
            border-radius: 10px;
            padding: 20px;

            h4 {
                font-weight: 800;
                font-size: 18px;
                margin-bottom: 15px;
            }

            a {
                display: block;
                margin-bottom: 15px;

                .list {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 5px;
                    }
            
                    h5 {
                        font-size: 16px;
                        font-weight: 700;
                        color: #323232;
                    }
            
                    p {
                        font-size: 14px;
                        color: #828282;
                        font-weight: 400;
                        margin-bottom: 0px;
                    }
                }
            }

            a:last-child {
                margin-bottom: 0px;
            }

            a:hover {
                .list {
                    h5 {
                        text-decoration: underline;
                    }
                }
            }
        }

        .details-box {
            width: 100%;
            background-color: rgba($color: #323232, $alpha: 0.9);
            padding: 25px 20px;
            border-radius: 10px;
            margin-top: 15px;

            h5 {
                color: #FFF;
                margin-bottom: 0px;
                font-weight: 700;
                margin-bottom: 15px;
            }

            .working-days {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 5px;
                margin-bottom: 15px;
                border-bottom: 1px solid rgba($color: #FFFFFF, $alpha: 0.3);

                .day {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    color: #FFF;
                }

                .time {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 0px;
                    color: #FFF;
                    font-weight: 400;
                }
            }

            .contact-details-heading {
                margin-top: 40px;
                margin-bottom: 0px;
            }

            .cta {
                margin-top: 10px;

                a {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    color: #FFF;
                    text-decoration: none;
                    font-size: 16px;

                    img {
                        height: 16px;
                        width: auto;
                        margin-right: 10px;
                    }
                }
            }
        }

        .image-box {
            position: relative;

            img {
                width: 100%;
                height: 210px;
                object-fit: cover;
                border-radius: 5px;
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                background-color: rgba($color: #000, $alpha: 0.3);
                display: flex;
                align-items: flex-end;

                .image-desc {
                    width: 100%;
                    margin: 10px;
                    background-color: #FFF;
                    border-radius: 5px;
                    padding: 5px 10px;

                    p {
                        text-align: center;
                        margin-bottom: 0px;
                        font-size: 13px;
                        font-weight: 800;
                    }
                }
            }
        }

        .gallery-sidebar {
            position: sticky;
            top: 0;
        }
    }
}