.press-media {
    .page-banner {
        padding: 80px 0px;
        background-image: url('../../../assets/page-banner.png');
        background-size: cover;
        text-align: center;

        h1 {
            color: #FFF;
            margin-bottom: 0px;
            font-weight: 800;
            font-size: 50px;
        }

        h5 {
            color: #FFF;
            font-weight: 500;
            font-style: italic;
            margin-top: 20px;
        }

        .appointment-btn {
            margin-top: 20px;
            background-color: #2F71B3;
            border-color: #2F71B3;
            font-size: 15px;
            font-weight: 600;
            padding: 10px 20px;
        }
    }

    .about-video {
        padding: 50px 0px;

        .video-box {
            position: relative;

            video {
                width: 675px;
                height: 450px;
                object-fit: cover;
                border-radius: 10px;
            }

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 10px;
            }

            .overlay {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: rgba($color: #323232, $alpha: 0.5);
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                .play-btn {
                    background-color: #FFF;
                    border-color: #FFF;
                    border-radius: 50%;
                    padding: 17px 20px;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                    svg {
                        margin-top: -5px;
                    }
                }
            }
        }

        .about-details {
            height: 100%;
            display: flex;
            align-items: center;
            background-color: #F4F7F9;
            border-radius: 10px;
            padding: 0px 50px;

            h2 {
                font-size: 30px;
                font-weight: 700;
                margin-bottom: 0px;
            }

            .tagline {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 0px;
                margin-top: 20px;
                font-style: italic;
            }

            .desc {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 0px;
                margin-top: 20px;
                color: #727070;
            }
        }

        .iframe-videos {
            margin-top: 20px;

            .iframe-box {
                width: 100%;
                border-radius: 10px;
                background-color: #F4F7F9;
                padding: 15px;

                .youtube-video-iframe {
                    width: 100%;
                    height: 250px;
                    border-radius: 10px;
                }

                video {
                    width: 100%;
                    height: 250px;
                    border-radius: 10px;
                }

                h5 {
                    font-size: 16px;
                    font-weight: 600;
                    font-style: italic;
                    margin-top: 10px;
                    line-height: 24px;
                }
            }
        }
    }

    .img-slider {
        padding: 30px 0px;
        
        h2 {
            font-size: 35px;
            margin-bottom: 0px;
            font-weight: 700;
            color: #000;
            text-align: center;
        }

        .achievements-slider {
            margin-top: 30px;

            .slider-image {
                width: 100%;
                height: 250px;
                object-fit: cover;
                border-radius: 10px;
            }

            .swiper-button-next {
                height: 35px !important;
                width: 35px !important;
                top: 55%;
            }

            .swiper-button-prev {
                height: 35px !important;
                width: 35px !important;
                top: 55%;
            }


            .swiper-button-next:after,
            .swiper-button-prev:after {
                background-color: #FFF;
                font-size: 15px !important;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 35px;
                width: 35px;
                font-weight: 700;
                color: #000;
            }
        }
    }
}