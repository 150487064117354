.contact-us {
    .page-banner {
        padding: 80px 0px;
        background-image: url('../../../assets/page-banner.png');
        background-size: cover;
        text-align: center;

        h1 {
            color: #FFF;
            margin-bottom: 0px;
            font-weight: 800;
            font-size: 50px;
        }

        h5 {
            color: #FFF;
            font-weight: 500;
            font-style: italic;
            margin-top: 20px;
        }

        .appointment-btn {
            margin-top: 20px;
            background-color: #2F71B3;
            border-color: #2F71B3;
            font-size: 15px;
            font-weight: 600;
            padding: 10px 20px;
        }
    }

    .address-map {
        padding: 50px 0px;

        .google-map {
            width: 100%;
            height: 400px;
            border-radius: 10px;
        }
    }

    .message-form {
        padding-bottom: 50px;

        h2 {
            font-weight: 800;
            margin-bottom: 20px;
            color: #323232;
        }

        .details-box {
            width: 100%;
            background-color: rgba($color: #323232, $alpha: 0.9);
            padding: 25px 20px;
            z-index: 100;

            h5 {
                color: #FFF;
                margin-bottom: 0px;
                font-weight: 700;
                margin-bottom: 15px;
            }

            .working-days {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 5px;
                margin-bottom: 15px;
                border-bottom: 1px solid rgba($color: #FFFFFF, $alpha: 0.3);

                .day {
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    color: #FFF;
                }

                .time {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 0px;
                    color: #FFF;
                    font-weight: 400;
                }
            }

            .contact-details-heading {
                margin-top: 30px;
                margin-bottom: 0px;
            }
            
            .social-media-icons {
                display: flex;
                align-items: center;
                margin-top: 10px;

                a {
                    display: block;
                    padding-right: 15px;
                }
            }


            .cta {
                margin-top: 10px;

                a {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    color: #FFF;
                    text-decoration: none;
                    font-size: 15px;

                    img {
                        height: 16px;
                        width: auto;
                        margin-right: 10px;
                    }
                }
            }
        }


        .submit-btn {
            background-color: #2F71B3;
            font-size: 14px;
            font-weight: 600;
            padding: 5px 20px;
        }
    }
}