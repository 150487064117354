/* GOOGLE FONTS */
@import url("https://fonts.googleapis.com/css2?family=Anuphan:wght@100;200;300;400;500;600;700&display=swap");

/* LAYOUT STYLES */
@import "./layout/header.scss";
@import "./layout/footer.scss";
@import "./responsive.scss";

/* PAGES STYLES */
@import "./pages/home";
@import "./pages/about-us";
@import "./pages/contact-us";
@import "./pages/gallery";
@import "./pages/publications-and-blogs";
@import "./pages/services/";
@import "./pages/services/service-detail.scss";
@import "./pages/press-media/index.scss";

body {
    font-family: "Anuphan", sans-serif;
    overflow-x: hidden;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    max-width: 1400px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.form-group {
    margin-bottom: 15px;

    .form-label {
        font-size: 13px;
        font-weight: 600;
        color: #323232;
        margin-bottom: 5px;

        span {
            color: red;
        }
    }

    .form-control {
        background-color: #f4f4f4;
        font-size: 14px;
        font-weight: 500;
        border: 0px solid transparent;
        padding: 10px 20px;
    }

    .form-control.validation-bg {
        background-color: rgba($color: red, $alpha: 0.1);
    }

    .datepicker-control {
        background-color: #f4f4f4;
        border: 0px solid transparent;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 20px;
        width: 100%;
        border-radius: 5px;
    }

    .textarea-control {
        width: 100%;
        background-color: #f4f4f4;
        border-radius: 5px;
        padding: 8px 20px;
        width: 100%;
        border: 0px solid transparent;
        font-size: 14px;
    }

    .textarea-control.validation-bg {
        background-color: rgba($color: red, $alpha: 0.1);
    }
}

.h-100 {
    height: 100% !important;
}

.validation-text {
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
    color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}
