.homepage {
    .about-video {
        padding: 50px 0px;

        .video-box {
            position: relative;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }

            .overlay {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: rgba($color: #323232, $alpha: 0.5);
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                .play-btn {
                    background-color: #fff;
                    border-color: #fff;
                    border-radius: 50%;
                    padding: 17px 20px;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                    svg {
                        margin-top: -5px;
                    }
                }
            }
        }

        .about-details {
            height: 100%;
            display: flex;
            align-items: center;
            background-color: #f4f7f9;
            border-radius: 10px;
            padding: 0px 50px;

            h2 {
                font-size: 30px;
                font-weight: 700;
                margin-bottom: 0px;
            }

            .tagline {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 0px;
                margin-top: 20px;
                font-style: italic;
            }

            .desc {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 0px;
                margin-top: 20px;
                color: #727070;
            }
        }
    }

    .banner {
        background: url("../../../assets/banner.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 100px 0px;
        position: relative;

        h1 {
            font-size: 45px;
            font-weight: 800;
            margin-bottom: 20px;
        }

        h4 {
            margin-bottom: 20px;
        }

        .appointment-btn {
            background-color: #2f71b3;
            border-color: #2f71b3;
            font-size: 14px;
            font-weight: 700;
            padding: 10px 25px;
        }

        .contact-form {
            background-color: #fff;
            border-radius: 10px;
            padding: 30px 20px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            h5 {
                font-size: 20px;
                font-weight: 700;
                color: #323232;
                margin-bottom: 20px;
            }

            .send-message-btn {
                width: 100%;
                background-color: #2f71b3;
                font-size: 14px;
                padding: 8px 20px;
                font-weight: 600;
            }
        }

        .top-index {
            z-index: 10;
        }

        .bg-overlay {
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba($color: #323232, $alpha: 0.5);
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }

    .speciality {
        margin: 50px 0px;

        .box {
            background-color: #f3f3f3;
            border-radius: 10px;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            .about-details {
                padding: 20px;

                h5 {
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 0px;
                }

                h2 {
                    margin-top: 10px;
                    font-weight: 800;
                    margin-bottom: 0px;
                }

                h4 {
                    font-size: 18px;
                    margin-top: 10px;
                    font-style: italic;
                    font-weight: 600;
                }

                .cta-btns {
                    display: flex;
                    align-items: center;

                    .cta {
                        background-color: transparent;
                        border-color: transparent;
                        border-right: 2px solid #000;
                        border-radius: 0px;
                        padding: 0px 40px 0px 0px;

                        p {
                            margin-bottom: 0px;
                            font-size: 14px;
                            color: #727070;
                            font-weight: 500;
                            text-align: left;
                        }

                        .number {
                            color: #323232;
                            margin-left: 5px;
                            font-weight: 600;
                        }
                    }

                    .cta:last-child {
                        border-right: 0px solid transparent;
                        padding-left: 40px;
                    }
                }

                .expertise-box {
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    padding: 15px;
                    border-radius: 5px;

                    img {
                        width: 45px;
                        height: auto;
                    }

                    h5 {
                        font-size: 16px;
                        margin-top: 15px;
                        font-weight: 700;
                        margin-bottom: 10px;
                    }

                    p {
                        color: #727070;
                        font-size: 14px;
                        margin-bottom: 15px;
                        font-weight: 500;
                    }

                    .learn-more {
                        width: 100%;
                        background-color: #2f71b3;
                        border-color: #2f71b3;
                        font-size: 14px;
                        font-weight: 700;
                        color: #fff;
                        padding: 5px 20px;
                    }
                }
            }
        }
    }

    .why-questions {
        padding: 50px 0px;

        .question-box {
            position: relative;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba($color: #323232, $alpha: 0.5);
                width: 100%;
                height: 100%;
                border-radius: 10px;
                display: flex;
                align-items: flex-end;

                .green-box {
                    width: 100%;
                    background-color: rgba($color: #00ae86, $alpha: 0.8);
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 5px 20px;

                    p {
                        color: #fff;
                        font-weight: 600;
                        text-align: center;
                        margin-bottom: 0px;
                        font-size: 18px;
                    }
                }
            }
        }

        .questions-section {
            padding: 0px 20px;

            h2 {
                font-size: 30px;
                font-weight: 800;
                margin-bottom: 0px;
                color: #323232;
            }

            p {
                font-size: 18px;
                color: #727070;
                margin-bottom: 0px;
                margin-top: 15px;
            }
        }
    }

    .about-me {
        background-color: #f4f7f9;
        padding: 50px 0px;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }

        h2 {
            font-size: 30px;
            margin-bottom: 5px;
            font-weight: 800;
            color: #000;
        }

        p {
            margin-top: 15px;
            color: #727070;
            font-size: 18px;
        }

        .know-more-btn {
            background-color: #2f71b3;
            border-color: #2f71b3;
            font-size: 14px;
            font-weight: 600;
            padding: 10px 30px;
        }
    }

    .testimonials {
        padding: 50px 0px;

        h2 {
            font-size: 30px;
            font-weight: 800;
            margin-bottom: 15px;
            color: #000;
        }

        .testimonial-box {
            position: relative;
            border-radius: 5px;
            padding: 50px 20px 20px 20px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            p {
                font-size: 15px;
                color: #727070;
                margin-bottom: 0px;
                font-weight: 400;
            }

            h5 {
                font-size: 15px;
                color: #000;
                margin-bottom: 0px;
                font-weight: 600;
                margin-top: 20px;
            }

            .user-profile {
                position: absolute;
                top: -30px;
                height: 60px;
                width: 60px;
                border-radius: 50%;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }
    }

    .latest-blogs {
        padding: 50px 0px;

        .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            h2 {
                font-size: 30px;
                font-weight: 800;
                margin-bottom: 0px;
                color: #000;
            }

            .link-item {
                text-decoration: none;
                color: #323232;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }

        .blog-box {
            display: block;
            padding: 10px;
            border-radius: 5px;
            text-decoration: none;

            .blog-img {
                width: 100%;
                height: auto;
                border-radius: 5px;
            }

            h5 {
                font-size: 16px;
                line-height: 22px;
                text-decoration: none;
                color: #323232;
                margin-top: 10px;
                font-weight: 600;
            }

            p {
                color: #828282;
                font-size: 13px;
                text-decoration: none;
                font-weight: 400;
                line-height: 18px;
                margin-bottom: 5px;
            }

            .learn-more-link {
                font-size: 15px;
                color: #00ae86;
                font-weight: 600;
                text-decoration: none;
            }
        }

        .blog-box:hover {
            padding: 10px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
    }

    .instagram-wall {
        padding-bottom: 60px;

        h2 {
            font-size: 30px;
            font-weight: 700;
        }

        img,
        video {
            width: 100%;
            height: 500px;
            object-fit: cover;
            border-radius: 5px;
        }

        .insta-image-02 {
            width: 100%;
            height: 200px !important;
        }
    }
}
