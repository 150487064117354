.our-blogs {
    .page-banner {
        padding: 80px 0px;
        background-image: url('../../../assets/page-banner.png');
        background-size: cover;
        text-align: center;

        h1 {
            color: #FFF;
            margin-bottom: 0px;
            font-weight: 800;
            font-size: 50px;
        }

        h5 {
            color: #FFF;
            font-weight: 500;
            font-style: italic;
            margin-top: 20px;
        }

        .appointment-btn {
            margin-top: 20px;
            background-color: #2F71B3;
            border-color: #2F71B3;
            font-size: 15px;
            font-weight: 600;
            padding: 10px 20px;
        }
    }

    .blogs-list {
        padding: 50px 0px;

        .featured-blogs {
            background-color: rgba($color: #2F71B3, $alpha: 0.1);
            border-radius: 10px;
            padding: 10px;

            h5 {
                font-weight: 800;
                padding-left: 10px;
                margin-bottom: 5px;
            }
        }

        .blog-latest-box {
            padding: 10px;

            img {
                width: 100%;
                height: auto;
                border-radius: 5px;
            }

            h5 {
                font-size: 16px;
                line-height: 22px;
                text-decoration: none;
                color: #323232;
                font-weight: 600;
                padding-left: 0px;
            }

            p {
                color: #828282;
                font-size: 13px;
                text-decoration: none;
                font-weight: 400;
                line-height: 18px;
                margin-bottom: 5px;
            }

            .learn-more-link {
                font-size: 15px;
                color: #00AE86;
                font-weight: 600;
                text-decoration: none;
            }
        }

        .blog-latest-box:hover {
            padding: 10px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        .blog-box {
            height: 100%;
            display: block;
            padding: 10px;
            border-radius: 5px;
            text-decoration: none;
    
            .blog-img {
                width: 100%;
                height: auto;
                border-radius: 5px;
            }
    
            h5 {
                font-size: 16px;
                line-height: 22px;
                text-decoration: none;
                color: #323232;
                margin-top: 10px;
                font-weight: 600;
            }
    
            p {
                color: #828282;
                font-size: 13px;
                text-decoration: none;
                font-weight: 400;
                line-height: 18px;
                margin-bottom: 5px;
            }
    
            .learn-more-link {
                font-size: 15px;
                color: #00AE86;
                font-weight: 600;
                text-decoration: none;
            }
        }
    
        .blog-box:hover {
            padding: 10px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
    }
}

.blog-detail {
    .page-banner {
        padding: 80px 0px;
        background-image: url('../../../assets/page-banner.png');
        background-size: cover;
        text-align: center;

        h1 {
            color: #FFF;
            margin-bottom: 0px;
            font-weight: 800;
            font-size: 50px;
        }

        h5 {
            color: #FFF;
            font-weight: 500;
            font-style: italic;
            margin-top: 20px;
        }

        .appointment-btn {
            margin-top: 20px;
            background-color: #2F71B3;
            border-color: #2F71B3;
            font-size: 15px;
            font-weight: 600;
            padding: 10px 20px;
        }
    }

    .blog-content {
        padding: 50px 0px;

        img {
            width: 100%;
            height: 500px;
            object-fit: cover;
            border-radius: 5px;
        }

        h5 {
            font-weight: 800;
            font-size: 25px;
            color: #323232;
            margin-top: 20px;
        }

        p {
            font-weight: 400;
            margin-bottom: 0px;
            margin-top: 15px;
            color: #323232;
        }

        ul {
            li {
                font-weight: 400;
                margin-top: 10px;
                color: #323232;
            }
        }

        .blog-detail-sidebar {
            position: sticky;
            top: 0;
        }

        .contact-form {
            width: 100%;
            padding: 20px;
            border: 1px solid #E5E5E5;
            border-radius: 5px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            margin-bottom: 15px;

            h4 {
                font-weight: 800;
                margin-bottom: 10px;
            }

            .submit-btn {
                width: 100%;
                border-color: #2F71B3;
                background-color: #2F71B3;
                font-size: 14px;
                font-weight: 700;
            }
        }

        .blogs-list {
            background-color: rgba($color: #2F71B3, $alpha: 0.1);
            border-radius: 10px;
            padding: 20px;

            h4 {
                font-weight: 800;
                font-size: 18px;
                margin-bottom: 15px;
            }

            a {
                display: block;
                margin-bottom: 15px;

                .list {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 5px;
                    }

                    h5 {
                        font-size: 16px;
                        font-weight: 700;
                        color: #323232;
                        margin-top: 0px;
                    }

                    p {
                        font-size: 14px;
                        color: #828282;
                        font-weight: 400;
                        margin-bottom: 0px;
                        margin-top: 0px;
                    }
                }
            }

            a:last-child {
                margin-bottom: 0px;
            }

            a:hover {
                .list {
                    h5 {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}