.header {
    /* TOP HEADER */
    .top-header {
        background-color: #2F71B3;
        padding: 10px 0px;

        .top-header-links {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left-section {
                display: flex;
                align-items: center;

                .link-item {
                    font-size: 14px;
                    color: #FFF;
                    font-weight: 500;
                    display: block;
                    text-decoration: none;
                    padding: 0px 20px;
                }
            }

            .right-section {
                display: flex;
                align-items: center;

                .telephone {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: #FFF;
                    font-weight: 500;
                    text-decoration: none;
                    padding: 0px 20px;

                    img {
                        width: 16px;
                        height: auto;
                        margin-right: 10px;
                    }
                }

                .link-item {
                    font-size: 14px;
                    color: #FFF;
                    font-weight: 500;
                    display: block;
                    text-decoration: none;
                    padding: 0px 20px;
                }
            }
        }
    }

    /* HEADER */
    .header-wrap {
        padding: 15px 0px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        .logo {
            width: 250px;
            height: auto;
        }

        .header-left {
            h5 {
                font-family: 'Lora', sans-serif;
                font-weight: 700;
                margin-bottom: 0px;
                font-size: 22px;
            }

            p {
                font-family: 'Lora', sans-serif;
                font-style: italic;
                margin-bottom: 0px;
                margin-top: 5px;
                font-size: 14px;
                font-weight: 600;
                color: #828282;
            }
        }

        .header-right {
            .logo {
                h5 {
                    font-family: 'Lora', sans-serif;
                    font-weight: 700;
                    margin-bottom: 0px;
                    font-size: 22px;
                }
            
                p {
                    font-family: 'Lora', sans-serif;
                    font-style: italic;
                    margin-bottom: 0px;
                    margin-top: 5px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #828282;
                }
            }

            .main-menu {
                .menu-link {
                    color: #323232;
                    font-weight: 600;
                    padding: 0px 25px;
                    font-size: 15px;
                }

                .menu-link-dropdown {
                    a {
                        color: #323232;
                        font-weight: 600;
                        padding: 0px 25px;
                        font-size: 15px;
                    }

                    .dropdown-menu {
                        margin-top: 10px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-right: 20px;
                        width: 350px;
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                        border: 0px solid transparent;

                        a {
                            display: block;
                            font-size: 15px;
                            padding-bottom: 10px;
                            white-space: break-spaces;
                        }

                        a:last-child {
                            padding-bottom: 0px;
                        }

                        a:hover {
                            background-color: #FFF;
                        }
                    }
                }
            }
        }
    }
}